<template>
    <v-card :class="{
        'mb-3 mr-3': $vuetify.breakpoint.xs, 
        'ml-3': $vuetify.breakpoint.smAndDown,
        'mt-3 ml-3 mb-0': $vuetify.breakpoint.mdAndUp, 
        'pa-2' : true, 
        'body-2' : true, 
        'd-flex align-center justify-start' : true }" elevation="2">
        <v-list-item-avatar>
            <v-img src="../assets/profile.png"></v-img>
        </v-list-item-avatar>
        <h4>{{ $t('dashboard.hello') }} Richard Martin</h4>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Usercard',
    computed: {
        ...mapGetters({
            students: 'dashboardOnline/getStudents',
            presentStudents : 'dashboardOnline/presentStudents',
            absentStudents: 'dashboardOnline/absentStudents',
            illStudents: 'dashboardOnline/illStudents',
            unExcusedStudents: 'dashboardOnline/unExcusedStudents',
            loggedOutStudents: 'dashboardOnline/loggedOutStudents',
            dayInfo: 'dashboardOnline/dayInfo',
        })
    }
}
</script>

<style>

</style>